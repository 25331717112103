import CodeName from './CodeName';
import Concept from './concept';

export default class ConceptShop extends CodeName {
  public Concepts: Concept[] = [];

  static convertAll = (items: any[]): ConceptShop[] => {
    const shops: ConceptShop[] = [];
    items.forEach(i => {
      let shop:ConceptShop|undefined = shops.find(s => s.code === i.shopCode);
      if (!shop) {
        shop = new ConceptShop(i.shopCode, i.shopName);
        shops.push(shop);
      }     
      shop.Concepts.push(Concept.convert(i));
    });
    return shops;
  };  

}