import { FC, useContext, useRef } from 'react';
import { KioskContext } from '../../../../context/KioskContext';
import styles from './styles.module.scss';
import { useTranslation } from "react-i18next";
import { Typography } from '@mui/material';
import SalePath from '../../../../models/SalePath';
import WaitSign from '../WaitSign';
import ListItem from '../ListItem';
import { KioskActionsEnum } from '../../../../context/KioskContext/types';

interface Props {
}

const ConceptList: FC<Props> = () => {
  const {state, dispatch } = useContext(KioskContext);

  const {t} = useTranslation();
  const refContainer = useRef<HTMLDivElement|null>(null);
  const refThis = useRef<HTMLDivElement|null>(null);

  const conceptClick = (salePath:SalePath) => {
    dispatch({type: KioskActionsEnum.SELECT_NODE, salePath: salePath});
  }

  const getActiveConcepts = () : SalePath[] => {
    // const concepts:SalePath[] = SalePath.sort(state.salePathData.rootItems).filter(c => 
    //   c.id > 0 && (c.openingHours == null || c.openingHours.isOpen())
    // );
    const concepts:SalePath[] = SalePath.sort(state.salePathData.rootItems).filter(c => 
      c.id > 0
    );
    return concepts;
  }

  const concepts = getActiveConcepts();

  return (
    <div ref={refThis} className={styles.conceptsContainer}>

      <div className={styles.concepts}>
        {!state.isDataLoaded ? (
          <div className={styles.isLoading}>
            <div className={styles.wait}>
              <WaitSign/>
            </div>
          </div>
        ) : 
            concepts.length===0?(
              <>
              </>
            ):(
              <>
                <Typography variant="h2" >{t('caption.conceptHeader')}</Typography>
                <div ref={refContainer} className={styles.conceptsList} >
                  {concepts.map((salePath: SalePath, index: number)=>(<ListItem key={index} primary={salePath.name} salePath={salePath} onClick={()=>conceptClick(salePath)} />))}
                </div>
              </>
            )
        }
        {/* <Footer/> */}
      </div>
    </div>
  );
};

export default ConceptList;
