import { FC, ReactNode, useContext } from 'react';
import { KioskContext } from '../../../../context/KioskContext';
import { KioskActionsEnum } from '../../../../context/KioskContext/types';
import { useTranslation } from "react-i18next";
import styles from './styles.module.scss';
import { Breadcrumbs, Typography, useTheme } from '@mui/material';
import SalePath from '../../../../models/SalePath';

const SalePathBreadcrumbs: FC = () => {

  const {state, dispatch} = useContext(KioskContext);
  const selected:SalePath|null = state.salePath;
  const theme = useTheme();

  const {t} = useTranslation();

  const nodeClicked = (node: SalePath|null) => {
    if (node)
      dispatch({type: KioskActionsEnum.SELECT_NODE, salePath: node});
    else
      dispatch({type: KioskActionsEnum.SELECT_ROOT});
  }

  const renderLink = (node:SalePath|null, prefix:string='', index:number=0) : ReactNode => {
    return (
      <div className={styles.breadcrumb} key={index} onClick={() => nodeClicked(node)}>
        {node ? (
          <Typography>{prefix}{node.name}</Typography>
        ) : (
          <Typography>{prefix}{t('caption.root')}</Typography>
        )}
      </div>
    );
  }

  const renderSingleLink = () : ReactNode => {
    if (!selected)return (<></>);
    const links:ReactNode[] = [];
    links.push(renderLink(null));

    const route:SalePath[] = selected.getRootChain();
    const node:SalePath|null = route.length>0 ? route[route.length-2] : null

    return renderLink(node, '<< ');
  }

  return (
    <div className={styles.breadcrumbs}>
      <Breadcrumbs separator='<<' style={{color: `${theme.palette.text.primary}`}}>
        {renderSingleLink()}
      </Breadcrumbs>
    </div>
  );
};

export default SalePathBreadcrumbs;
