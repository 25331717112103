import { ErrorInfo, FC, useCallback, useContext, useEffect, useState } from 'react';
import { KioskContext } from '../../../../context/KioskContext';
import styles from './styles.module.scss';
import { Typography, useTheme } from '@mui/material';
import { KioskActionsEnum, StepsEnum } from '../../../../context/KioskContext/types';
import { useTranslation } from "react-i18next";
import Shop from '../../Controls/Shop';
import Line from '../../Controls/Line';
import DeliveryMethod from '../../Controls/DeliveryMethod';
import BasketProductList from '../../Controls/BasketProductList';
import EmailEntry from '../../Controls/EmailEntry';
import { getCartState } from '../../../../helpers/localStorageHelper';
import { formatMoney } from '../../../../helpers/formatHelper';
import { DeliveryMethodEnum, LoyaltyCardTypeEnum, addProductRow, deleteProductRow, setDeliveryMethod, setEmail, setLoyaltyCardData, setPickupTime, setRegistrationNumber, setTableNumber } from '../../../../helpers/basketApi';
import DeliveryTime from '../../Controls/DeliveryTime';
import SalePathData from '../../../../models/SalePathData';
import SalePath from '../../../../models/SalePath';
import ErrorBoundary from '../../ErrorBoundary';
import { handleApplicationError, postLogEntry, SeverityEnum } from '../../../../helpers/loggerApi';
import ProductData from '../../../../models/productData';
import { startPayment } from '../../../../helpers/paytrailApi';
import i18n from '../../../../i18n/i18n';
import Product from '../../../../models/product';
import useQueryParams from '../../../../hooks/useQueryParams';
import LoyaltyCardEntry from '../../Controls/LoyaltyCardEntry';
import dayjs from 'dayjs';
import Button, { ButtonTypes } from '../../Controls/Button';

interface Props {
  height:number;
}

const Order: FC<Props> = ({height}) => {

  const {state, dispatch} = useContext(KioskContext);
  const {t} = useTranslation();
  const queryParams = useQueryParams();
  const fixedTable: string|null = queryParams.tableNumber;
  const theme = useTheme();
  const [ refreshing, refresh ] = useState<boolean>(false);
  useEffect(()=>{if (refreshing)refresh(false)},[refreshing]);

  const [curbsideItem] = useState<SalePath|undefined>(SalePathData.getCurbsideItem(state.salePathData));

  const defaultDeliveryMethod = () : DeliveryMethodEnum => {
    if (fixedTable)return DeliveryMethodEnum.TableService;
    if (state.cart==null)return DeliveryMethodEnum.FrontCounter;
    return state.cart.deliveryMethod;
  }

  const [executingPayment, setExecutingPayment] = useState<boolean>(false);
  const [selectedDeliveryMethod, setSelectedDeliveryMethod] = useState<DeliveryMethodEnum>(defaultDeliveryMethod);
  const [deliveryValidates, setDeliveryValidates] = useState<boolean>(false);
  const [pickupTimeValidates, setPickupTimeValidates] = useState<boolean>(true);

  const [loyaltyCardValidates, setLoyaltyCardValidates] = useState<boolean>(true);
  const [emailValidates, setEmailValidates] = useState<boolean>(false);

  const clearExtraSale = useCallback(async (item: SalePath|undefined): Promise<void> => {
    if (!item || !state.cart)return;
    var product:Product|undefined = state.cart.products.find(p => p.productCode===item.productCode);
    while(product){
      const cart = await deleteProductRow(state.cart.id, product.rowId);
      if (cart==null){
        dispatch({ type: KioskActionsEnum.RESET_CART });
        return;
      }
      dispatch({ type: KioskActionsEnum.UPDATE_CART, cart: cart });
      product = cart.products.find(p => p.productCode===item.productCode);
    }
  }, [dispatch, state.cart])  

  const setExtraSale = useCallback(async (item: SalePath|undefined): Promise<void> => {
    if (!item || !state.cart)return;
    var product:Product|undefined = state.cart.products.find(p => p.productCode===item.productCode);
    if (product)return;
    const saleProduct:ProductData = new ProductData(
      item.productCode,
      1,
      "",
      null,
      '',
      item.code,
      item.isContent,
      []);
    const cart = await addProductRow(state.cart.id, saleProduct);
    if (cart==null){
      dispatch({ type: KioskActionsEnum.RESET_CART });
      return;
    }
    dispatch({ type: KioskActionsEnum.UPDATE_CART, cart: cart });
  }, [dispatch, state.cart])  

  const updateDeliveryMethod = useCallback(async (deliveryMethod:DeliveryMethodEnum): Promise<void> => {
    if (state.cart && state.cart.deliveryMethod !== deliveryMethod){
      var cart = await setDeliveryMethod(state.cart.id, deliveryMethod);
      if (cart==null){
        dispatch({ type: KioskActionsEnum.RESET_CART });
        return;
      }
      dispatch({ type: KioskActionsEnum.UPDATE_CART, cart: cart });
    }
  }, [dispatch, state.cart])  

  useEffect(()=>{
    if (selectedDeliveryMethod === state.cart?.deliveryMethod)return;
    updateDeliveryMethod(selectedDeliveryMethod);
    clearExtraSale(curbsideItem);
    switch(selectedDeliveryMethod){
      case DeliveryMethodEnum.Curbside:{
        setExtraSale(curbsideItem);
      }
    }
  },[clearExtraSale, curbsideItem, selectedDeliveryMethod, setExtraSale, state.cart?.deliveryMethod, updateDeliveryMethod]);

  const proceedWithOrdering = () => {
    dispatch({ type: KioskActionsEnum.SET_STEP, step:StepsEnum.collect });
  }

  const updateRegistrationNumber = async (registrationNumber:string) => {
    if (state.cart && (state.cart.registrationNumber||'') !== registrationNumber){
      var cart = await setRegistrationNumber(state.cart.id, registrationNumber.trim());
      if (cart==null){
        dispatch({ type: KioskActionsEnum.RESET_CART });
        return;
      }
      dispatch({ type: KioskActionsEnum.UPDATE_CART, cart: cart });
    }
  };

  const updateTableNumber = async (tableNumber:string) => {
    if (state.cart && (state.cart.tableNumber||'') !== tableNumber){
      var cart = await setTableNumber(state.cart.id, tableNumber.trim());
      if (cart==null){
        dispatch({ type: KioskActionsEnum.RESET_CART });
        return;
      }
      dispatch({ type: KioskActionsEnum.UPDATE_CART, cart: cart });
    }
  };

  const updatePickupTime = async (pickupTime: string) => {
    if (state.cart && (state.cart.pickUpTime||'') !== pickupTime){
      var cart = await setPickupTime(state.cart.id, pickupTime);
      if (cart==null){
        dispatch({ type: KioskActionsEnum.RESET_CART });
        return;
      }
      dispatch({ type: KioskActionsEnum.UPDATE_CART, cart: cart });
    }
  };

  const updateLoyaltyCard = async (cardType: LoyaltyCardTypeEnum, cardNumber: string) => {
    if (state.cart){
      var cart = await setLoyaltyCardData(state.cart.id, cardType, cardNumber);
      if (cart==null){
        dispatch({ type: KioskActionsEnum.RESET_CART });
        return;
      }
      dispatch({ type: KioskActionsEnum.UPDATE_CART, cart: cart });
    }
  };

  const updateEmail = async (email:string) => {
    if (state.cart && (state.cart.email||'') !== email){
      var cart = await setEmail(state.cart.id, email.trim());
      if (cart==null){
        dispatch({ type: KioskActionsEnum.RESET_CART });
        return;
      }
      dispatch({ type: KioskActionsEnum.UPDATE_CART, cart: cart });
    }
  };

  const finalizePayment = async () => {
    if (!state.cart)return;

    if (!state.cart.serviceID){
      // Update Cart properties

      const cartState = getCartState();

      await updateRegistrationNumber(selectedDeliveryMethod===DeliveryMethodEnum.Curbside ? cartState.registrationNumber||'':'');
      
      await updateTableNumber(selectedDeliveryMethod===DeliveryMethodEnum.TableService ? cartState.tableNumber||'':'');
      
      await updatePickupTime( (cartState.deliveryMethod !== DeliveryMethodEnum.TableService && cartState.pickupTime ) ? dayjs(cartState.pickupTime,'YYYYMMDDHHmm').format('YYYY-MM-DD HH:mm'): '');

      if ((process.env.REACT_APP_LOYALTYCARDS||'').length>0){
        cartState.loyaltyCards.forEach(async (c) => {
          await updateLoyaltyCard(c.card as LoyaltyCardTypeEnum.Plussa, c.cardNumber||'');
        });
      }

      //const plussaCard: string = getLocalDefault('LoyaltyCard_Plussa', '');
      // if ((process.env.REACT_APP_LOYALTYCARDS||'').toLowerCase().indexOf('plussa')>=0){
      //   await updateLoyaltyCard(LoyaltyCardTypeEnum.Plussa, plussaCard);
      // }

      await updateEmail(cartState.email||'');

    }
  }

  const proceedWithPayment = async () => {
    if (!state.cart)return;

    if (SalePathData.getInvalidProducts(state.salePathData, state.cart).length>0){
      dispatch({ type: KioskActionsEnum.SET_STEP, step:StepsEnum.cart });
      return;
    }

    if (executingPayment)return;
    setExecutingPayment(true);
    try {
      await finalizePayment();
      let cart = state.cart;
  
      const redirectUrl:string = await startPayment(cart.id, i18n.language);
  
      postLogEntry({severity: SeverityEnum.Debug, message: `Paytrail payment redirect. Url:${redirectUrl}. Location:${window.location}`}, cart.id);
      
      if (redirectUrl == null || redirectUrl.trim().length===0){
        postLogEntry({severity: SeverityEnum.Error, message: `Paytrail payment. Missing Redirect Url`}, cart.id);
        return;
      }
        
      setExecutingPayment(false);
      window.location.href = redirectUrl;
    } finally {
      setExecutingPayment(false);
    }
  }  

  const handleLoyaltyCardValidation = (isValid: boolean) => {
    setLoyaltyCardValidates(isValid);
    //refresh(true);
  };

  // function delay(ms: number) {
  //   return new Promise( resolve => setTimeout(resolve, ms) );
  // }

  const handleEmailValidation = (isValid: boolean) => {
    // (async () => { 
    //   await delay(100);
    //   setEmailValidates(isValid);
    // })()
    setEmailValidates(isValid);
    //refresh(true);
  };

  //const heightHeader:number = 55;
  const heightFooter:number = 50;
  //const heightContainer:number = window.outerHeight - heightHeader;
  //const heightContainer:number = window.innerHeight - heightHeader;
  const heightContainer:number = height;

  const onError = async (area: string, error:Error, info:React.ErrorInfo) => {
    handleApplicationError(area, state.cart?.id, undefined, error);
  }
  
  const denyToProceed = !emailValidates || !deliveryValidates || !pickupTimeValidates || !loyaltyCardValidates;

  const receiptTotal:number = (state.cart?.receiptTotal||0);
  const showDelimeters: boolean = false;
  
  return (
  <ErrorBoundary onError={(error:Error, info:ErrorInfo) => onError('Order', error, info)}>
    <div className={styles.order} style={{height: `${heightContainer}px`, minHeight: `${heightContainer}px`}}>
    {/* <div className={styles.order}> */}

      <div className={styles.header}>
        <div className={styles.titleItem}><Typography variant="h1">{t('caption.step-order')}</Typography></div>
        <div className={styles.titleItem}><Shop allowToChange={false} /></div>
      </div>

      {showDelimeters&&<Line short />}

      <div className={styles.titleItem}>
        <DeliveryMethod method={selectedDeliveryMethod} onMethodChange={(e:DeliveryMethodEnum)=>{setSelectedDeliveryMethod(e)}}
          onValidate={(e:boolean)=>{setDeliveryValidates(e)}} />
      </div>

      {showDelimeters&&<Line short />}

      {getCartState().deliveryMethod !== DeliveryMethodEnum.TableService && (
        <>

        <div className={styles.titleItem}>
        <DeliveryTime onValidate={(e:boolean)=>{setPickupTimeValidates(e)}} />
      </div>
      
      {showDelimeters&&<Line short />}

      </>
      )}
    
    <div className={styles.titleItem}>
      <Typography variant="h2">{t('caption.selected-products')}</Typography>
      <BasketProductList />
      <div className={styles.totals}>
        <Typography variant="h3" >{t('caption.total')}</Typography>
        <Typography variant="h3" >{formatMoney(receiptTotal)} €</Typography>
      </div>
      </div>
      <div className={styles.titleItem}>
        <div className={styles.continue}>
          <Button buttontype={ButtonTypes.Text} onClick={proceedWithOrdering} className={styles.button}>
              <Typography variant="h3" sx={{color: theme.palette.text.secondary}}>+ {t('button.continue-order')}</Typography>
          </Button>
        </div>
      </div>

      {showDelimeters&&<Line short />}

      {((process.env.REACT_APP_LOYALTYCARDS||'').length>0) && (
        <>
          <div className={styles.titleItem}>
            <LoyaltyCardEntry onLoyaltyCardValidate={handleLoyaltyCardValidation}/>
          </div>

          {showDelimeters&&<Line short />}
        </>
      )}

      {/* <Typography variant="h3" >Height1:{height}, innerHeight:{window.innerHeight}, outerHeight:{window.outerHeight}</Typography> */}

      <div className={styles.titleItem}>
        <EmailEntry onEmailValidate={handleEmailValidation}/>
      </div>

      {/* <div >
        TestFields:
        <Input error={false} name='email' label='Sähköposti' inputMode="email" value={testEmail} onValueChange={(value: string)=> setTestEmail(value)} autoComplete='off'/>
        <Input disabled={true} name='text' label='' inputMode="text" value={testText} onChange={(event: React.ChangeEvent<HTMLInputElement>)=> setTestText(event.target.value)} autoComplete='off'/>
      </div> */}

      <div className={styles.footerContent} style={{height: `${heightFooter}px`}}>
      {/* <div className={styles.footerContent}> */}
        {/* <button disabled={denyToProceed} onClick={proceedWithPayment} className={styles.button}>
          <div className={styles.buttonText}>
            <Typography variant="h3" >{t('button.pay-order')}</Typography>
          </div>
        </button> */}
        <Button buttontype={ButtonTypes.Primary} disabled={denyToProceed} onClick={proceedWithPayment} className={styles.button}>
          <div className={styles.buttonText}>
            <Typography variant="h3" >{t('button.pay-order')}</Typography>
          </div>
        </Button>
      </div>
    </div>
  </ErrorBoundary>
  );
};

export default Order;
