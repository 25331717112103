import { FC, useContext, useEffect, useState } from 'react';
import { KioskContext } from '../../../../context/KioskContext';
import styles from './styles.module.scss';
import { Typography } from '@mui/material';
import { getCart } from '../../../../helpers/basketApi';
import { KioskActionsEnum } from '../../../../context/KioskContext/types';
import { useTranslation } from "react-i18next";
import Shop from '../../Controls/Shop';
import BasketProductList from '../../Controls/BasketProductList';
import Cart from '../../../../models/cart';
import { getLatestCartId, setLatestCartCompleted } from '../../../../helpers/localStorageHelper';
import { formatMoney } from '../../../../helpers/formatHelper';
import Button, { ButtonTypes } from '../../Controls/Button';

interface Props {
  cartId: string;
  height: number;
}

const Paid: FC<Props> = ({cartId, height}) => {

  const {dispatch} = useContext(KioskContext);
  const {t} = useTranslation();
  const [cart, setCart] = useState<Cart|null>();


  useEffect(()=>{
    const fetchCart = async () => {
      try {
        const cart = await getCart(cartId);
        setCart(cart);
        if (cart && cartId===getLatestCartId() && cart.state.toLowerCase()==='finalized') {
          setLatestCartCompleted();
        }
      }
      catch {
        setCart(null);
      }
    }
    fetchCart();
  },[cartId]);

  const clear = () => {
    dispatch({ type: KioskActionsEnum.RESET_CART });
    dispatch({ type: KioskActionsEnum.OBSERVE_CART, cartId: null});
    //console.log('location', window.location);
    window.location.href = window.location.origin;
  }

  const onFollowOrder = () => {
    dispatch({ type: KioskActionsEnum.SHOW_RECEIPTSTATUS, cartId: cart?.id});    
  }
  const onShowReceipt = () => {
    dispatch({ type: KioskActionsEnum.SHOW_RECEIPT, cartId: cart?.id});    
  }

  //const heightHeader:number = 55;
  //const heightContainer:number = window.innerHeight - heightHeader;
  const heightContainer:number = height;

  return (
  <div className={styles.order} style={{maxHeight:`${heightContainer}px`}}>

    <div className={styles.header}>
      <div className={styles.titleItem}><Typography variant="h1">{t('caption.step-paid')}</Typography></div>
      <div className={styles.titleItem}>
        <Typography variant="h2">{t('caption.order-completed')}</Typography>
        <Typography variant="h2">{t('caption.order-number')} {cart?.serviceID||'?'}</Typography>
      </div>
      <div className={styles.titleItem}><Shop allowToChange={false} /></div>
    </div>


    <div className={styles.buttons}>
      <Button buttontype={ButtonTypes.Primary} disabled={cart==null} onClick={onFollowOrder} className={styles.button}>
        <Typography variant="h3" >{t('button.order-follow')}</Typography>
      </Button>
      <Button buttontype={ButtonTypes.Primary} disabled={cart==null} onClick={onShowReceipt} className={styles.button}>
        <Typography variant="h3" >{t('button.order-receipt')}</Typography>
      </Button>
      <Button buttontype={ButtonTypes.Primary} onClick={clear} className={styles.button}>
        <Typography variant="h3" >{t('button.return-to-start')}</Typography>
      </Button>
    </div>

    <div className={styles.orderContent}>
      <Typography variant="h3">{t('caption.order')}</Typography>
      {cart && (
        <div>
          <BasketProductList cart={cart} />
          <div className={styles.totals}>
            <Typography variant="h3" >{t('caption.total')}</Typography>
            <Typography variant="h3" >{formatMoney(cart.receiptTotal)} €</Typography>
          </div>
        </div>
      )}
    </div>
  </div>
  );
};

export default Paid;
