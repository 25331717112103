import Cart from '../models/cart';
import { ApiConfig, getConfig } from './apiConfig';
import { handleApiError } from './loggerApi';
import ProductData from '../models/productData';
import { getLatestShopCode } from './localStorageHelper';

const config: ApiConfig = getConfig();

export enum DeliveryMethodEnum {
  FrontCounter = 'FrontCounter',
  TableService = 'TableService',
  Takeaway = 'Takeaway',
  Curbside = 'Curbside'
}

export enum OrderStateEnum {
  Waiting = 'Waiting',
  Preparing = 'Preparing',
  Collecting = 'Collecting',
  Ready = 'Ready',
  Delivered = 'Delivered'
}

export enum LoyaltyCardTypeEnum {
  None = 'None',
  Plussa = 'Plussa',
}

export const getCart = async (cartId: string): Promise<Cart|null> => {
  try {
    const response = await fetch(`${config.apiUrl}/cart/${cartId}`, {
    });
    if (response.ok) {
      return await response.json();
    } else {
      if (response.status===404){
        return null;
      }
      if (response.status===500){
        console.log('500 virhe');
        return null;
      }
      handleApiError('getCart', response, cartId);
      return Promise.reject(await response.json());
    }
  } catch (error) {
    console.log('500 virhe!');
    handleApiError('getCart', error, cartId);
    throw error;
  }
};

export const initializeCart = async (): Promise<Cart> => {
  try {
    const response = await fetch(`${config.apiUrl}/cart`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        shopCode: getLatestShopCode(),
        posNumber: config.pos,
      }),
    });
    if (response.ok) {
      return await response.json();
    } else {
      handleApiError('initializeCart', response);
      return Promise.reject(await response.json());
    }
  } catch (error) {
    handleApiError('initializeCart', error, undefined, undefined);
    throw error;
  }
};

export const addProductRow = async (
    cartId: string,
    product: ProductData,
): Promise<Cart|null> => {
  try {
    const response = await fetch(`${config.apiUrl}/cart/${cartId}/products`, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(product),
      });
      if (response.ok) {
        return response.json();
      } else {
        if (response.status===404){
          return null;
        }
        if (response.status===500){
          console.log('500 virhe');
          return null;
        }
        handleApiError('addProductRow', response, cartId, {product:product});
        return Promise.reject(response.json());
      }
  } catch (error) {
    handleApiError('addProductRow', error, cartId, {product:product});
    throw error;
  }
};

export const updateProductRow = async (
    cartId: string,
    rowId: string,
    quantity: number,
): Promise<Cart|null> => {
  try {
    const response = await fetch(`${config.apiUrl}/cart/${cartId}/products`, {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        rowId,
        quantity,
      }),
    });
    if (response.ok) {
      return  await response.json();
    } else {
      if (response.status===404){
        return null;
      }
      if (response.status===500){
        console.log('500 virhe');
        return null;
      }
      handleApiError('updateProductRow', response, cartId, {rowId:rowId, quantity:quantity});
      return Promise.reject(response.json());
    }
  } catch (error) {
    handleApiError('updateProductRow', error, cartId, {rowId:rowId, quantity:quantity});
    throw error;
  }
};

export const deleteProductRow = async (
    cartId: string,
    rowId: string,
): Promise<Cart|null> => {
  try {
    const response = await fetch(`${config.apiUrl}/cart/${cartId}/products/${rowId}`, {
      method: 'delete',
    });
    if (response.ok) {
      return await response.json();
    } else {
      if (response.status===404){
        return null;
      }
      if (response.status===500){
        console.log('500 virhe');
        return null;
      }
      handleApiError('deleteProductRow', response, cartId, {rowId:rowId});
      return Promise.reject(response.json());
    }
  } catch (error) {
    handleApiError('deleteProductRow', error, cartId, {rowId:rowId});
    throw error;
  }
};

export const getServiceId = async (
    cartId: string,
): Promise<Cart|null> => {
  try {
    const response = await fetch(`${config.apiUrl}/cart/${cartId}/serviceid`, {
    });
    if (response.ok) {
      return await response.json();
    } else {
      if (response.status===404){
        return null;
      }
      if (response.status===500){
        console.log('500 virhe');
        return null;
      }
      handleApiError('getServiceId', response, cartId);
      return Promise.reject(response.json());
    }
  } catch (error) {
    handleApiError('getServiceId', error, cartId);
    throw error;
  }
};

export const setDeliveryMethod = async (
    cartId: string,
    deliveryMethod: DeliveryMethodEnum,
): Promise<Cart|null> => {
  try {
    const response = await fetch(`${config.apiUrl}/cart/${cartId}/deliverymethod`, {
      method: 'post',
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(deliveryMethod),
    });
    if (response.ok) {
      return await response.json();
    } else {
      if (response.status===404){
        return null;
      }
      if (response.status===500){
        console.log('500 virhe');
        return null;
      }
      handleApiError('setDeliveryMethod', response, cartId, {deliveryMethod:deliveryMethod});
      return Promise.reject(response.json());
    }
  } catch (error) {
    handleApiError('setDeliveryMethod', error, cartId, {deliveryMethod:deliveryMethod});
    throw error;
  }
};

export const setLoyaltyCardData = async (
  cartId: string,
  cardType: LoyaltyCardTypeEnum,
  cardNumber: string,
): Promise<Cart|null> => {
try {
  const response = await fetch(`${config.apiUrl}/cart/${cartId}/loyaltycard`, {
    method: 'post',
    headers: {
        'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      cardType: cardType,
      cardNumber: cardNumber 
    }),
  });
  if (response.ok) {
    return await response.json();
  } else {
    if (response.status===404){
      return null;
    }
    if (response.status===500){
      console.log('500 virhe');
      return null;
    }
    handleApiError('setLoyaltyCardData', response, cartId, {cardType:cardType, cardNumber:cardNumber});
    return Promise.reject(response.json());
  }
} catch (error) {
  handleApiError('setLoyaltyCardData', error, cartId, {cardType:cardType, cardNumber:cardNumber});
  throw error;
}
};


export const setEmail = async (
  cartId: string,
  email: string,
): Promise<Cart|null> => {
  try {
    const response = await fetch(`${config.apiUrl}/cart/${cartId}/email`, {
      method: 'post',
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(email),
    });
    if (response.ok) {
      return await response.json();
    } else {
      if (response.status===404){
        return null;
      }
      if (response.status===500){
        console.log('500 virhe');
        return null;
      }
      handleApiError('setEmail', response, cartId, {email:email});
      return Promise.reject(response.json());
    }
  } catch (error) {
    handleApiError('setEmail', error, cartId, {email:email});
    throw error;
  }
};

export const setRegistrationNumber = async (
  cartId: string,
  registrationNumber: string,
): Promise<Cart|null> => {
  try {
    const response = await fetch(`${config.apiUrl}/cart/${cartId}/registrationNumber`, {
      method: 'post',
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(registrationNumber),
    });
    if (response.ok) {
      return await response.json();
    } else {
      if (response.status===404){
        return null;
      }
      if (response.status===500){
        console.log('500 virhe');
        return null;
      }
      handleApiError('setRegistrationNumber', response, cartId, {registrationNumber:registrationNumber});
      return Promise.reject(response.json());
    }
  } catch (error) {
    handleApiError('setRegistrationNumber', error, cartId, {registrationNumber:registrationNumber});
    throw error;
  }
};

export const setTableNumber = async (
  cartId: string,
  tableNumber: string,
): Promise<Cart|null> => {
  try {
    const response = await fetch(`${config.apiUrl}/cart/${cartId}/tableNumber`, {
      method: 'post',
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(tableNumber),
    });
    if (response.ok) {
      return await response.json();
    } else {
      if (response.status===404){
        return null;
      }
      if (response.status===500){
        console.log('500 virhe');
        return null;
      }
      handleApiError('setTableNumber', response, cartId, {tableNumber:tableNumber});
      return Promise.reject(response.json());
    }
  } catch (error) {
    handleApiError('setTableNumber', error, cartId, {tableNumber:tableNumber});
    throw error;
  }
};

export const setPickupTime = async (
  cartId: string,
  pickupTime: string,
): Promise<Cart|null> => {
  try {
    const response = await fetch(`${config.apiUrl}/cart/${cartId}/pickuptime`, {
      method: 'post',
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify(pickupTime),
    });
    if (response.ok) {
      return await response.json();
    } else {
      if (response.status===404){
        return null;
      }
      if (response.status===500){
        console.log('500 virhe');
        return null;
      }
      handleApiError('setPickupTime', response, cartId, {pickupTime:pickupTime});
      return Promise.reject(response.json());
    }
  } catch (error) {
    handleApiError('setPickupTime', error, cartId, {pickupTime:pickupTime});
    throw error;
  }
};


export const getReceipt = async (
  cartId: string,
  format: string,
): Promise<Blob> => {
  try {
    const response = await fetch(`${config.apiUrl}/cart/${cartId}/receipt/download/${format}`, {
      method: 'post',
      headers: {
          'Content-Type': 'application/json',
      }
    });
    if (response.ok) {
      return await response.blob();
    } else {
      handleApiError('getReceipt', response, cartId, {format:format});
      return Promise.reject(response.json());
    }
  } catch (error) {
    handleApiError('getReceipt', error, cartId, {format:format});
    throw error;
  }
};

// export const addPayment = async (
//   cartId: string,
//   amount: number,
//   paymentCode: string = '0',
//   referenceCode: string = ''
// ): Promise<Cart> => {
//   try {
//     const response = await fetch(`${apiUrl}/cart/${cartId}/payments`, {
//       method: 'post',
//       headers: {
//           'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({paymentCode: paymentCode, amount: amount, referenceCode: referenceCode}),
//     });
//     if (response.ok) {
//       return await response.json();
//     } else {
//       handleApiError('addPayment', response, cartId, {paymentCode:paymentCode, amount:amount, referenceCode:referenceCode});
//       return Promise.reject(response.json());
//     }
//   } catch (error) {
//     handleApiError('addPayment', error, cartId, {paymentCode:paymentCode, amount:amount, referenceCode:referenceCode});
//     throw error;
//   }
//};
