import { FC } from 'react';
import styles from './styles.module.scss';
import { CompanyLogo } from '../CompanyLogo/CompanyLogo';
import BasketIcon from '../BasketIcon';
import Line from '../Line';
import LanguageDropDown from '../LanguageDropDown';

interface Props {
  disabled?: boolean;
}

const Header: FC<Props> = ({disabled}) => {

  const showDelimeters: boolean = false;

  return (
    <>
      <div className={styles.header}>
          <div className={styles.areaCompany}>
            <CompanyLogo logoName={process.env.REACT_APP_COMPANY_NAME} />
          </div>
          <div className={styles.areaLanguage}>
            <LanguageDropDown disabled={disabled}/>
          </div>
          <div className={styles.areaBasket}>
            <BasketIcon disabled={disabled} />
          </div>
      </div>
      {showDelimeters&&<Line short />}
    </>
  )
};


export default Header;
