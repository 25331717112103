import { FC } from 'react';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { Typography, useTheme } from '@mui/material';
import ConceptShop from '../../../models/conceptShop';
import ShopList from '../../components/Controls/ShopList';

interface Props {
}

const Shop: FC<Props> = () => {
  const {t} = useTranslation();
  const theme = useTheme();

  const maxWidth:number|null = 600;

  const shopClick = (shop:ConceptShop) => {
    console.log('location', window.location);
    window.location.href = `${window.origin}?shop=${shop.code}`;
  }

  return (
    <div className={styles.shop} style={{maxWidth:`${maxWidth&&`${maxWidth}px`}`, backgroundColor: theme.palette.background.default}}>
      <Typography className={styles.title} variant="h2">{t('caption.select-shop')}</Typography>
      <ShopList onClick={shopClick} />
    </div>
  );
};

export default Shop;
