import ConceptShop from '../models/conceptShop';
import SalePath from '../models/SalePath';
import { getConfig } from './apiConfig';
import { getLatestShopCode } from './localStorageHelper';
import { handleApiError } from './loggerApi';

const apiUrl: string = getConfig().apiUrl;

export const getSalePaths = async (language?: string): Promise<Array<SalePath>> => {
  try {
    const shopCode:string = getLatestShopCode()||'';
    const response = await fetch(`${apiUrl}/search/${shopCode}/salepath${language!==undefined?`?language=${language}`:''}`, {
    });
    if (response.ok) {
      return SalePath.convertAll(await response.json());
    } else {
      handleApiError('getSalePaths', response);
      return Promise.reject(await response.json());
    }
  } catch (error) {
    handleApiError('getSalePaths', error);
    throw error;
  }
};

export const getSalePathConcepts = async (allShops: boolean = false, language?: string): Promise<Array<ConceptShop>> => {
  try {
    const shopCode:string = getLatestShopCode()||'';
    const response = await fetch(`${apiUrl}/search/${shopCode}/salepathconcept?allShops=${allShops}${language!==undefined?`&language=${language}`:''}`, {});
    if (response.ok) {
      return ConceptShop.convertAll(await response.json());
    } else {
      handleApiError('getSalePathConcepts', response);
      return Promise.reject(await response.json());
    }
  } catch (error) {
    handleApiError('getSalePathConcepts', error);
    throw error;
  }
};

export const getDocument = async (documentCode: string, language?: string): Promise<string> => {
  try {
    const shopCode:string = getLatestShopCode()||'';
    const response = await fetch(`${apiUrl}/search/${shopCode}/document/${documentCode}${language!==undefined?`?language=${language}`:''}`, {
    });
    if (response.ok) {
      return await response.text();
    } else {
      handleApiError('getDocument', response);
      return Promise.reject(await response.json());
    }
  } catch (error) {
    handleApiError('getDocument', error);
    throw error;
  }
};
