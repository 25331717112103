import './App.scss';
import React, { FC, useCallback, useContext, useEffect, useState } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { KioskContext } from '../context/KioskContext';
import { KioskActionsEnum } from '../context/KioskContext/types';
import Config from '../hooks/Config';
import Shop from './pages/Shop';
import Basket from './pages/Basket';
import useQueryParams from '../hooks/useQueryParams'
import { getLatestShopCode, setLatestCartId, setLatestShopCode } from '../helpers/localStorageHelper';
import ErrorBoundary from './components/ErrorBoundary';
import { handleApplicationError } from '../helpers/loggerApi';
import Checkout from './pages/Checkout';
import Cart from '../models/cart';
import { getCart } from '../helpers/basketApi';
import SalePath from '../models/SalePath';
import { getSalePaths } from '../helpers/searchApi';
import { useTranslation } from 'react-i18next';
//import useHeightGuard from '../hooks/useHeightGuard';
//import useKeyboardState from '../hooks/useKeyboardState';

const App: FC = () => {
  const {state, dispatch} = useContext(KioskContext);
  const {i18n} = useTranslation();
  const [ refreshing, refresh ] = useState<boolean>(false);
  useEffect(()=>{if (refreshing)refresh(false)},[refreshing]);
  //const {setEnabled: setHeightGuardEnabed} = useHeightGuard();

  const {version, debugMode } = Config();
//  const {enabled: keyboarStateEnabled, setEnabled: setKeyboarStateEnabled} = useKeyboardState(true);

  const queryParams = useQueryParams();
  var navigateTo:string|null=null;

  const shopCode:string|null = queryParams.shopCode || getLatestShopCode();
  const tableNumber:string|null = queryParams.tableNumber?.toUpperCase() || null;
  const cartId:string|null = queryParams.cartId||null;

  useEffect((): void => {
    //setHeightGuardEnabed(true);
    if (Boolean(JSON.parse(process.env.REACT_APP_DEBUG||'0'))){
      document.documentElement.setAttribute('debug-mode', 'enabled')
    }
  }, [])

  useEffect(() => {
  } ,[shopCode]);

  if (shopCode){
    if (shopCode !== getLatestShopCode()){
      setLatestShopCode(shopCode);
      dispatch({type: KioskActionsEnum.UPDATE_CART, cart:null});
      setLatestCartId(cartId);
    }
  } else if (cartId) {
    setLatestCartId(cartId);
  } else {
    const url:string = process.env.REACT_APP_SHOPS_URI||'/shop';
    navigateTo = url;
  }

  useEffect((): void => {
    console.log('React', React.version);
  }, []);
  useEffect((): void => {
    console.log('Version', version);
  }, [version]);
  useEffect((): void => {
    if (debugMode){
      console.log('DEBUG ON');
    }
  }, [debugMode]);
  useEffect((): void => {
    if (debugMode){
      if (queryParams.shopCode){
        console.log(`QueryParameter 'Shop': ${queryParams.shopCode}`);
      }
      if (queryParams.tableNumber){
        console.log(`QueryParameter 'Table': ${queryParams.tableNumber}`);
      }
      if (queryParams.cartId){
        console.log(`QueryParameter 'Cart': ${queryParams.cartId}`);
      }
    }
  }, [debugMode, queryParams.shopCode, queryParams.tableNumber, queryParams.cartId]);

  const fetchSalePaths = useCallback(async (shopCode: string = getLatestShopCode()||'AAA'): Promise<void> => {
    dispatch({type: KioskActionsEnum.SELECT_ROOT});
    const data: SalePath[] = await getSalePaths(i18n.language);
    dispatch({type: 'LOADED', salePaths: data});
  }, [dispatch, i18n.language])

  const fetchCart = useCallback(async (cartId:string): Promise<void> => {
    if (state.settings!=null){
      try {
        const cart: Cart|null = await getCart(cartId);
        if (cart){
          /// TODO Failed to find Cart
          dispatch({type: KioskActionsEnum.UPDATE_CART, cart:cart});
          setLatestCartId(cart.id);
          if (cart.shop.shopCode !== getLatestShopCode()){
            setLatestShopCode(cart.shop.shopCode);
          }
          if (!state.isDataLoaded){
            fetchSalePaths(cart.shop.shopCode);
          }
          if (cart.state.toLowerCase()==='finalized'){
            dispatch({ type: KioskActionsEnum.OBSERVE_CART, cartId: cartId });
          }
        }
      }
      catch {
      }
    }
  }, [dispatch, fetchSalePaths, state.isDataLoaded, state.settings])

  useEffect((): void => {
    if (queryParams.cartId && state.cart?.id !== queryParams.cartId){
      fetchCart(queryParams.cartId);
    }
  }, [fetchCart, queryParams.cartId, state.cart?.id]);
  
  const onError = async (error:Error, info:React.ErrorInfo) => {
    handleApplicationError('Root', state.cart?.id, undefined, error);
  }

  return (
    <div className="App">
      <Router>
        <ErrorBoundary onError={onError}>
            <Routes>
              { navigateTo && <Route path={navigateTo} />}
              <Route path="/" element={ ((shopCode&&shopCode!=='')||(cartId&&cartId!=='')) ? <Basket shopCode={state.cart?.shop.shopCode||shopCode||''} tableNumber={tableNumber}/> : <Shop/> }/> 
              <Route path="/shop" element={<Shop/>}/>
              <Route path="/checkout" element={<Checkout/>}/>
              <Route path="*" element={<>404</>}/>
            </Routes>
          </ErrorBoundary>
      </Router>
    </div>
  );
}

export default App;
