import { FC, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { useTranslation } from "react-i18next";
import { TextField, Typography } from '@mui/material';
import { getCartState, setCartState } from '../../../../helpers/localStorageHelper';

interface Props {
  onEmailValidate?: (result: boolean) => void;
}

const EmailEntry: FC<Props> = ({onEmailValidate}) => {
  const {t} = useTranslation();

  const defaultEmail = () : string => {
    return getCartState().email||'';
  }

  const validateEmailAddress = (email:string) : boolean => {
    const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return regex.test(email);
  }

  const validateEmail1 = (value1:string = email1, value2:string = email2) : boolean => {
    if (value1.trim().length===0 || !validateEmailAddress(value1))return false;
    //console.log('validateEmail2', `>${emailOther}< >${email}<`);
    if (value2.trim().length>0 && (value1.toLowerCase() !== value2.toLowerCase()))return false;
    return true;
  };
  const validateEmail2 = (value1:string = email1, value2:string = email2) : boolean => {
    if (value2.trim().length===0 || !validateEmailAddress(value2))return false;
    //console.log('validateEmail2', `>${email}< >${emailOther}<`);
    if (value1.toLowerCase() !== value2.toLowerCase())return false;
    return true;
  };

  const validateEmails = (a:string = email1,b:string = email2) : boolean => {
    return validateEmail1(a,b) && validateEmail2(b,a);
  };

  const [email1, setEmail1] = useState<string>(defaultEmail());
  const [email2, setEmail2] = useState<string>(defaultEmail());
  const [validated, setValidted] = useState<boolean>(validateEmails());

  useEffect(()=>{
    if (onEmailValidate)onEmailValidate(validated);
  }, [onEmailValidate, validated]);

  const handleEmail1Change = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value.length<100) {
      const val:string = event.target.value.trim();
      setEmail1(val);
      const state = getCartState();
      state.email = val;
      setCartState(state);
      setValidted(validateEmails(val, email2));
    }
  };
  const handleEmail2Change = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value.length<100) {
      const val:string = event.target.value.trim();
      setEmail2(val);
      setValidted(validateEmails(email1, val));
    }
  };

  const email1Error:boolean = !validateEmail1();
  const email2Error:boolean = !validateEmail2();

  return (
    <div className={styles.emailEntry}>
      <div className={styles.emailPart}><Typography variant="h2" >{t(`caption.contact-info`)}</Typography></div> 
      <div className={styles.emailPart}><Typography variant="body1" >{t(`caption.contact-info-description`)}</Typography></div>

      <div className={styles.emailPart} style={{display:'flex'}}>
        <div className={styles.emailPart}><Typography variant="h3">{t(`caption.contact-info-email1`)}</Typography></div>
        <div className={styles.emailPart}><Typography variant="body1">({t(`caption.mandatory`)})</Typography></div>
      </div>
      {/* <div className={styles.emailPart}><input inputMode='email' className={styles.emailText} value={email1} onChange={handleEmail1Change} autoComplete='off'/></div> */}
      <div className={styles.emailPart}><TextField id='em1' variant="outlined" type='email' error={email1Error} className={styles.emailText} value={email1} onChange={handleEmail1Change} sx={{width:'100%'}} autoComplete='off'/></div>
      {/* <div className={styles.emailPart}><Input id='em1' inputMode="email" error={email1Error} className={styles.emailText} value={email1} onChange={handleEmail1Change} autoComplete='off'/></div> */}

      <div className={styles.emailPart} style={{display:'flex'}}>
        <div className={styles.emailPart}><Typography variant="h3">{t(`caption.contact-info-email2`)}</Typography></div>
        <div className={styles.emailPart}><Typography variant="body1">({t(`caption.mandatory`)})</Typography></div>
      </div>
      {/* <div className={styles.emailPart}><input inputMode='email' className={styles.emailText} value={email2} onChange={handleEmail2Change} autoComplete='off'/></div> */}
      <div className={styles.emailPart}><TextField id='em2' variant="outlined" type='email' error={email2Error} className={styles.emailText} value={email2} onChange={handleEmail2Change} sx={{width:'100%'}} autoComplete='off'/></div>
      {/* <div className={styles.emailPart}><Input id='em2' inputMode="email" error={email2Error} className={styles.emailText} value={email2} onChange={handleEmail2Change} autoComplete='off'/></div> */}

    </div>
  );
};

export default EmailEntry;
