export interface ApiConfig {
  apiUrl: string;
  apiKey: string;
  pos: number;
}

const apiUrl: string = process.env.REACT_APP_BASKET_URI || '';
const apiKey: string = process.env.REACT_APP_BASKET_KEY || '';
const pos: number = Number.parseInt(process.env.REACT_APP_POSNUMBER || '0');

export const getConfig = (): ApiConfig => {
    return {apiUrl: apiUrl, apiKey: apiKey, pos: pos};
};