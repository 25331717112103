import { ErrorInfo, FC, ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { KioskContext } from '../../../context/KioskContext';
import styles from './styles.module.scss';
import { useTranslation } from "react-i18next";
import ConceptList from '../Controls/ConceptList';
import SalePathListItems from '../Controls/SalePathListItems';
import SalePathBreadcrumbs from '../Controls/SalePathBreadcrumbs';
import SalePathProduct from '../Controls/SalePathProduct';
import BasketDialog from '../Dialogs/BasketDialog';
import Header from '../Controls/Header';
import Welcome from '../Controls/Welcome';
import Shop from '../Controls/Shop';
import { StepsEnum } from '../../../context/KioskContext/types';
import Line from '../Controls/Line';
import Order from '../Steps/Order';
import Paid from '../Steps/Paid';
import Receipt from '../Steps/Receipt';
import ReceiptStatus from '../Steps/ReceiptStatus';
import OpenCart from '../Controls/OpenOrder';
import useWindowState from '../../../hooks/useWindowState';
import { handleApplicationError } from '../../../helpers/loggerApi';
import ErrorBoundary from '../ErrorBoundary';
import Footer from '../Controls/Footer';
// import EditItemNode from '../../../models/EditItemNode';
// import EditItem from '../../../models/EditItem';

interface Props {
  shopCode: string;
  tableNumber: string|null;
}

const Main: FC<Props> = ({shopCode, tableNumber}) => {
  const {state} = useContext(KioskContext);
  const { portrait, width: windowWidth, height: windowHeight } = useWindowState();

  const [ refreshing, refresh ] = useState<boolean>(false);
  const {i18n} = useTranslation();

  const htmlStateKey:string = 'data-state';
  const htmlState:string = document.documentElement.getAttribute(htmlStateKey)||'init';

  const refMainFrame = useRef<HTMLDivElement|null>(null);
  const refHeader = useRef<HTMLDivElement|null>(null);
  const refFooter = useRef<HTMLDivElement|null>(null);

  useEffect((): void => {
    var htmlStateUpdate:string = state.step ? state.step.toString() : '';
    if (htmlState !== htmlStateUpdate){
      document.documentElement.setAttribute(htmlStateKey, htmlStateUpdate);
    }
  }, [htmlState, state.step])

  useEffect(()=>{if (refreshing)refresh(false)},[refreshing]);

  useEffect(()=>{refresh(true)},[i18n.language]);
  useEffect(()=>{
    refresh(true);
  },[state.salePath]);

  const showBasketButton: boolean = htmlState.toLowerCase()==='collect';

  useEffect(()=>{
    refresh(true);
  },[portrait, windowHeight]);

  useEffect(()=>{
    window.dispatchEvent(new Event('resize'));
  },[showBasketButton]);

  const onError = async (area: string, error:Error, info:React.ErrorInfo) => {
    handleApplicationError(area, state.cart?.id, undefined, error);
  }

  const isProductSelected: boolean = state.salePath?.nodeType.toLowerCase() === 'product';
  const isCartActive:boolean = (state.cart&&(state.cart.products.length > 0 && state.cart.state.toLowerCase() !== 'finalized'))||false;
  //const showFooter:boolean = ( !isProductSelected );
  const showCartButton:boolean = ( isCartActive && !isProductSelected );
  

  //const heightHeader:number = 56;
  const heigthBreadcrumb:number = 50;
  const maxWidth:number|null = 600;
  const width:number = windowWidth ? windowWidth < maxWidth ? windowWidth : maxWidth : maxWidth;

  const widthContainer:number = width - 8 - 8;
  const heightFooter: number = (refFooter.current?.clientHeight || 0) + (showCartButton ? 4 : 0);
  //const heightContainer:number = window.innerHeight - heightHeader;
  //const heightContainer:number = ((refMainFrame.current?.offsetHeight || 0) - (refHeader.current?.offsetHeight || 0))-5;
  //const heightContainer:number = ((refMainFrame.current?.getBoundingClientRect().height || 0) - (refHeader.current?.getBoundingClientRect().height || 0))-5;
  const heightContainer:number = (windowHeight||0 - (refHeader.current?.clientHeight || 0))-5;

  
  const heightContent:number = heightContainer - heightFooter;
  const heightBrowsing:number = heightContent - heigthBreadcrumb - 50;

  const showDelimeters: boolean = false;
  const showFooter: boolean = !isProductSelected && !(state.showReceiptStatusCartId || state.showReceiptCartId || state.observedCartId || state.step === StepsEnum.order);

  const content = (height: number = heightContainer) : ReactNode => {
    if (state.showReceiptStatusCartId){
      return <ReceiptStatus cartId={state.showReceiptStatusCartId} height={height} />
    }
    if (state.showReceiptCartId){
      return <Receipt cartId={state.showReceiptCartId} height={height} />
    }
    if (state.observedCartId){
      return <Paid cartId={state.observedCartId} height={height} />
    }
    if (state.step === StepsEnum.order){
      return <Order height={height} />;
    }

    // if (state.salePath == null)
    //   console.log('Concepts', showCartButton, heightContainer);
    // else {
    //   if (isProductSelected)
    //     console.log('SalePathProduct', showCartButton, heightContainer);
    //   else
    //   console.log('SalePathListItems', showCartButton, heightContainer);
    // }

    return (state.salePath == null) ? (
      <div className={styles.concepts} style={{height:`${heightContent-50}px`, maxHeight:`${heightContent-50}px`}}>
        <div className={styles.conceptsHeader}>
          <div className={styles.headerItem}><Welcome/></div>
          <div className={styles.headerItem}><Shop/></div>
          {showDelimeters&&<div className={styles.headerItem}><Line short/></div>}
          </div>
          <ErrorBoundary onError={(error:Error, info:ErrorInfo) => onError('Concepts', error, info)}>
            <div className={styles.conceptsList}>
              <ConceptList/>
            </div>
          </ErrorBoundary>
        </div>
    ) : (
      <div className={styles.browse}>
        <div className={styles.breadcrumbs}>
          <SalePathBreadcrumbs/>
        </div>
        <div>
          {
            (isProductSelected) ? 
              <ErrorBoundary onError={(error:Error, info:ErrorInfo) => onError('SalePathItem', error, info)}>
                <div className={styles.product} style={{height:`${heightBrowsing}px`, minHeight:`${heightBrowsing}px`, maxHeight:`${heightBrowsing}px`, width:`${widthContainer+8}px`}}>
                  <SalePathProduct  />
                </div>
              </ErrorBoundary>
            :
              <ErrorBoundary onError={(error:Error, info:ErrorInfo) => onError('SalePathList', error, info)}>
                <div  className={styles.list} style={{height:`${heightBrowsing}px`, minHeight:`${heightBrowsing}px`, maxHeight:`${heightBrowsing}px`}}>
                  <SalePathListItems />
                </div>
              </ErrorBoundary>
          }
        </div>
      </div>
    );
  }

  // if (state.selectedNode){
  //   console.log('Main', 'EditItemNode', state.selectedNode);
  // }

  return (
    <div ref={refMainFrame} className={styles.root} style={{height:windowHeight||0, maxWidth:`${width&&`${width}px`}`}}>
      <div ref={refHeader} className={styles.header} id='header'>
        <Header/>
      </div>
      <div className={styles.mainContainer} style={{width:`${widthContainer}px`, maxWidth:`${widthContainer}px`}}>
        <div className={styles.content} id='content' style={{maxHeight:heightContent, width:`${widthContainer}px`, maxWidth:`${widthContainer}px`}}>
        {content(heightContainer-50)}
        </div>
        <div style={{height: 'auto'}}></div>
        {showFooter && (
        <div ref={refFooter} className={styles.footerContent} style={{width:`${width}px`}}>
          {showCartButton ? (
            <div className={styles.cartButton}>
              <OpenCart />
            </div>
          ) : showFooter && (

            <div className={styles.footer}>
              <Footer />
            </div>
          )}
        </div>
        )}
      </div>
      <BasketDialog visible={state.step === StepsEnum.cart} width={width} />
    </div>      
  );
};

export default Main;

