import { FC, ReactNode, useContext, useEffect, useState } from 'react';
import { KioskContext } from '../../../../context/KioskContext';
import styles from './styles.module.scss';
import { Typography } from '@mui/material';
import EditItem from '../../../../models/EditItem';
import ProductData from '../../../../models/productData';
import Cart from '../../../../models/cart';
import { addProductRow, deleteProductRow, initializeCart } from '../../../../helpers/basketApi';
import { KioskActionsEnum } from '../../../../context/KioskContext/types';
import SetCount from '../SetCount';
import AddToCart from '../AddToCart';
import ProductAllergens from '../ProductAllergens';
import ProductSpecialDiets from '../ProductSpecialDiets';
import { initializeCartState, setLatestCartId } from '../../../../helpers/localStorageHelper';
import ProductOption from '../ProductOption';
import ProductSelectManyItem from '../ProductOption/ProductSelectManyItem';
import SalePath from '../../../../models/SalePath';

interface Props {
}

const SalePathProduct: FC<Props> = () => {

  const {state, dispatch} = useContext(KioskContext);
  const [isNeedUpdate, setIsNeedUpdate] = useState<boolean>(false);

  const debug: boolean = false;

  useEffect((): void => {
    if (isNeedUpdate) {
      setIsNeedUpdate(false);
    }
  }, [isNeedUpdate]);

  const editItem:EditItem|null = state.editData?.rootItem || (state.salePath ? new EditItem(state.salePath): null);
  if (editItem==null)return;

  const changed = () => {
    setIsNeedUpdate(true);
  };

  const setProductCount = (count: number) => {
    if (count<1)count=1;
    if (count>9)count=9;

    editItem.quantity = count;
    setIsNeedUpdate(true);
  }

  const addProductToBasket = async () => {
    if (state.settings!=null){
      var cart: Cart | null = state.cart;
      const updatedProductData:ProductData = editItem.generateProductData();
      if (editItem.product?.rowId){
        if (cart!=null){
          cart = await deleteProductRow(cart.id, editItem.product?.rowId);
          if (cart==null){
            dispatch({ type: KioskActionsEnum.RESET_CART });
            return;
          }
          cart = await addProductRow(cart.id, updatedProductData);
        }
      } else {
        if (state.cart==null || state.cart.state.toLowerCase() === 'finalized'){
          cart = await initializeCart();
          setLatestCartId(cart.id);
          initializeCartState(cart.id);
        }
        if (cart!=null){
          cart = await addProductRow(cart.id, updatedProductData);
          if (cart==null){
            dispatch({ type: KioskActionsEnum.RESET_CART });
            return;
          }
        }
      }
      editItem.reset();
      dispatch({ type: KioskActionsEnum.UPDATE_CART, cart: cart });
      dispatch({type: KioskActionsEnum.SELECT_PARENT});
    }
  }

    const renderChildren = (parent: EditItem = editItem) : ReactNode => {
      if (debug)console.log('Parent', parent.salePath.name);
      const items:EditItem[] = EditItem.sort(parent.children);
      if (items.length===0)return (<></>);
      return (
      <div>
        <h3>{parent.salePath.name}</h3>
        {items.flatMap((c:EditItem, idx:number) => {
          const parentFreeUnits:number = c.salePath.parent?.chainFreeUnits || 0;
          const parentIsContent:boolean = c.salePath.parent?.isContent || false;

          if (debug)console.log('SalePath', c.salePath.name, c.salePath);

          if (c.salePath.hasChoices() && c.salePath.isProperty() === false){
            const selectMany:boolean = c.salePath.chainFreeUnits !==null ? c.salePath.chainFreeUnits > 0 : false;
            const choices:EditItem[] = EditItem.sort(c.children).filter(i => i.salePath.isProperty() && (i.salePath.isChainProductDefined || selectMany));
            if (debug)console.log('Step 1/2', 'ProductOption', choices.length===0 ? 'NO CHOICES' : `Choices: ${choices.length}`, c.salePath.name, choices);
            if (choices.length===0)return null;
            return <ProductOption key={idx} editItem={c} relatedChildren={choices} onChange={changed}/>;
          }

          if (c.salePath.isProperty()){
            if (!parentIsContent && parentFreeUnits === 0){
              if (debug)console.log('Step 3', 'ProductSelectManyItem', c.salePath.name, parentIsContent, parentFreeUnits);
              return <ProductSelectManyItem key={idx} editItem={c} onChange={changed} showPrice={(!(c.salePath.parent?.isContent||false) && c.salePath.salePrice!==0)} disabled={!c.salePath.isOptional}/>
            }
          }
          if (debug)console.log('Recurse', c.salePath.name);
          return renderChildren(c);
        })}
      </div>
      );
  }

  //const heightHeader:number = 100;
  //const heightContainer:number = window.innerHeight - heightHeader;
  const concept:SalePath = editItem.salePath.getRoot();
  if (!concept.isVisible){
    dispatch({type: KioskActionsEnum.SELECT_ROOT});
  }

  return (
  // <div className={styles.salePathProduct} style={{maxHeight:`${heightContainer}px`}}></div>
  <div className={styles.salePathProduct}>
    <div className={styles.scrollable}>
      <div className={styles.image}>
        <img src={editItem.salePath.imagePath} alt={editItem.salePath.imagePath}/>
      </div>
      <div className={styles.header}>
        <Typography variant="h2" >{editItem.salePath.name}</Typography>
        <Typography variant="h3" >{SalePath.showPrice(editItem.salePath)} €</Typography>
      </div>
      <div className={styles.description}>
        <Typography variant="body1" >{editItem.salePath.description}</Typography>
      </div>
      <div className={styles.actions}>
        <div className={styles.count}>
          <SetCount count={editItem.quantity} onChange={(count:number)=> setProductCount(count)} enabled={true} />
        </div>
        {/* <div className={styles.buttons}>
          <AddToCart editItem={editItem} onClick={addProductToBasket} />
        </div> */}
      </div>
      <ProductSpecialDiets node={editItem}/>
      <div className={styles.properties}>
        {renderChildren()}
      </div>
      <ProductAllergens node={editItem}/>
      <div style={{height:20}}></div>
      {editItem.salePath.getRoot().isVisible && (
        <AddToCart editItem={editItem} onClick={addProductToBasket} />
        // <div style={{bottom:8}}>
        //   <AddToCart editItem={editItem} onClick={addProductToBasket} />
        // </div>
      )} 
    </div>
  </div>
  );
};

export default SalePathProduct;
