import CodeName from './CodeName';
import SalePathOpeningHours from './SalePathOpeningHours';

export default class Concept extends CodeName {
  orderNumber: number;
  public imagePath: string;
  public openingHours: SalePathOpeningHours|null;

  constructor(
    code: string,
    name: string,
    orderNumber: number,
    imagePath: string,
    openingHours: SalePathOpeningHours|null,
    ) {
    super(code, name);
    this.orderNumber = orderNumber;
    this.imagePath = imagePath;
    this.openingHours = openingHours;
  }

  static convert = (item: any): Concept => {

    const concept: Concept = new Concept(item.salePathCode, item.salePathName, item.orderNumber, item.imagePath, SalePathOpeningHours.convert(item));
    return concept;
  };  

  public static sort  =(array:Concept[]) : Concept[] => {
    return array.sort((n1: Concept, n2: Concept) => n1.orderNumber - n2.orderNumber);
  }

}
