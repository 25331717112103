import { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { TextField, Typography } from '@mui/material';
import ListItem from '../ListItem';
import ConceptShop from '../../../../models/conceptShop';
import { useTranslation } from 'react-i18next';
import { getSalePathConcepts } from '../../../../helpers/searchApi';
import Concept from '../../../../models/concept';
import Button, { ButtonTypes } from '../Button';

interface Props {
  onClick?: (shop: ConceptShop) => void;
}

const ShopList: FC<Props> = ({onClick}) => {

  const [ isLoading, setIsLoading ] = useState<boolean>(false);
  const [ failedToLoad, setFailedToLoad ] = useState<boolean>(false);
  const [ concepts, setConcepts ] = useState<ConceptShop[]|undefined>();
  const {t, i18n} = useTranslation();
  const [ manualCode, setManualCode ] = useState<string>('');

  const fetchConcepts = useCallback(async (): Promise<void> => {
    try {
      setFailedToLoad(false);
      const result = await getSalePathConcepts(true, i18n.language)
      setConcepts(result);
    } catch (error) {
      setFailedToLoad(true);
      setIsLoading(false);
    }
    setIsLoading(false);
  }, [i18n.language])

  useEffect((): void => {
    if (concepts === undefined && !isLoading && failedToLoad === false) {
      setIsLoading(true);
      fetchConcepts();
    }
  }, [concepts, failedToLoad, fetchConcepts, isLoading])  

  const shopClick = (shop:ConceptShop) => {
    if (onClick)onClick(shop);
  }

  const buildSecondary = (shop: ConceptShop): string => {
    return Concept.sort(shop.Concepts).map((concept) => {return concept.name}).join(', ');
  }

  const handleManualCode = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value.length<50) {
      setManualCode(value);
    }
  };

  const commitManualCode = () => {
    const code:string = manualCode.trim();
    if (code.length>0){
      const shop: ConceptShop = new ConceptShop(code,'');
      if (onClick)onClick(shop);
    }
  };

  const buildSelect = () : ReactNode => {
    if (failedToLoad){
      return (
        <>
          <TextField variant="outlined" label={t('caption.code')} type='text' className={styles.shopCode} value={manualCode} onChange={handleManualCode} sx={{width:'100%'}} autoComplete='off'/>
          <Button buttontype={ButtonTypes.Primary} onClick={commitManualCode} className={styles.button}>
            <div className={styles.buttonText}>
              <Typography variant="h3" >{t('button.select')}</Typography>
            </div>
          </Button>
        </>
      );
    }
    if (isLoading||concepts===undefined)return <></>;
    return (
      <>{concepts.map((shop: ConceptShop, index: number) => (
        <ListItem key={index} onClick={()=>shopClick(shop)}
          primary={shop.name}
          secondary={buildSecondary(shop)}
          showIcon={false}
        />
      ))
      }
      </>
    );
  }

  return (
    <div className={styles.shopList}>
      {buildSelect()}
    </div>
  );
};

export default ShopList;
